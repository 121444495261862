/* Core Imports */
@import './variables.css';
@import './typography.css';
@import './grid.css';
@import './../style.css';

/* Error Page Styles */
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: var(--font-roboto);
  color: #ffffff;
  position: relative;
}

.error::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.error h1 {
  font-family: var(--font-roboto);
  font-size: 5.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.error h2 {
  font-family: var(--font-opensans);
  font-size: 2.75rem;
  margin-bottom: 1rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.error p {
  font-size: 1.25rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
  max-width: 600px;
  margin: 0 auto;
  padding: 0 var(--card-padding);
  line-height: 1.6;
}

.error .card {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.81);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .error h2 {
    font-size: 2.75rem;
  }

  .error p {
    font-size: 1.12rem;
    padding: 0 24px;
  }
}
